import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { logo } from "../../assets";
import "./TopBar.css";

export default function TopBar() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <section className={`navbar ${isScrolled ? "navbar--scrolled" : ""}`}>
      <div className="navbar__section navbar__icon">
        <Link to="/" aria-label="Home">
          <img className="navbar__logo" src={logo} alt="" />
        </Link>
      </div>

      <div className="navbar__section navbar_group">
        <Link to="/" aria-label="Home">
          <h1>Trang Chủ</h1>
        </Link>
        <Link to="/Guide">
          <h1>Hướng Dẫn Thuê Acc</h1>
        </Link>
      </div>

      <div className="navbar__section navbar__title">
        <a
          className="cus_link"
          href="https://www.facebook.com/LeHoang1995Z?mibextid=ZbWKwL"
          target="_blank"
          rel="noreferrer noopener"
        >
          Liên Hệ Admin
        </a>
      </div>
    </section>
  );
}
