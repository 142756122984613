import "./header.css";
import {banner} from "../../assets"

export default function Header() {
  return (
    <section className="Banner">
      <img className="Banner__img" src={banner} alt="HoangLeCF"/>
    </section>
  );
}
