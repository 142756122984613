import "./Popup.css";

const Popup = ({ onClose }) => {
  const handleOverlayClick = () => {
    onClose();
  };

  const handleConfirmClick = () => {
    onClose();
  };
  return (
    <section id="PopUp" className="popup__overlay" onClick={handleOverlayClick}>
      <div className="Popup__content">
        <div className="Popup__content-info">
          <p className="Content__header">Thông báo</p>
          <p className="Content__info-pay">
            {" "}
            Cho thuê acc Đột kích - Valorant giá rẻ
          </p>
          <p className="Content__info-pay">
            {" "}
            SHOP THUÊ ACC CF GIÁ RẺ - ACC VIP - UY TÍN
          </p>
          <p className="Content__info-pay">
            {" "}
            ĐỂ TRÁNH SCAM GIẢ MẠO SHOP AE VUI LÒNG LIÊN HỆ QUA:{" "}
          </p>
          <p className="Content__info-pay">📲 HOTLINE/Zalo: 0777.650.650</p>
          <p className="Content__info-pay">
            📌 Fanpage Facebook:{" "}
            <a
              className="popUpLink"
              href="https://www.facebook.com/profile.php?id=100087621974335"
            >
              {" "}
              Cho Thuê acc vip{" "}
            </a>
          </p>
          <p className="Content__info-pay">
            📌 CHỦ TÀI KHOẢN :{" "}
            <a
              className="popUpLink"
              href="https://www.facebook.com/LeHoang1995Z?mibextid=ZbWKwL"
            >
              {" "}
              NGUYỄN HOÀNG LÊ{" "}
            </a>
          </p>
        </div>
        <button onClick={handleConfirmClick} className="confirm">
          OK
        </button>
      </div>
    </section>
  );
};

export default Popup;
