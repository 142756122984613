import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import "./singlePost.css";

export default function SinglePost() {
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  const [post, setPost] = useState({});
  const PF = "https://backend.hoanglecf.vn/images/";
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [price, setPrice] = useState("");
  const [listImages, setListImages] = useState([]);

  useEffect(() => {
    const getPost = async () => {
      const res = await axios.get(
        "https://backend.hoanglecf.vn/api/posts/" + path
      );
      setPost(res.data);
      setTitle(res.data.title);
      setDesc(res.data.desc);
      setPrice(res.data.price);
      setListImages(res.data.listImages);
    };
    getPost();
  }, [path]);

  return (
    <div className="singlePost">
      <div className="singlePostWrapper">
        {post.photo && (
          <img src={PF + post.photo} alt="" className="singlePostImg" />
        )}
        <div className="singlePostTitle__row">
          <p className="singlePostTitle">
            <span>Mã số - Tên Acc:</span>
            <br />
            {title}
          </p>
          <p className="singlePostDesc">
            <span>Acc vip:</span>
            <br />
            {desc}
          </p>
        </div>
        <p className="singlePostDesc">
          <span>Giá Thuê: </span>
          <br />
          {price}₫
        </p>
        {/* <div className="imageGrid"> */}
        {listImages.map((img, index) => (
          <div key={index} className="imagePreviewItem">
            <img src={PF + img} alt="" />
          </div>
        ))}
        {/* </div> */}
      </div>
    </div>
  );
}
