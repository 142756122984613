import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import Header from "../../components/header/Header";
import Posts from "../../components/posts/Posts";
import Popup from "../../components/Popup/Popup";
import "./homepage.css";
import axios from "axios";
import { phone, tiktok, zaloIcon, messenger, fbIco } from "../../assets/index";
export default function Homepage() {
  const [showPopup, setShowPopup] = useState(false);
  const [posts, setPosts] = useState([]);
  const [active, setActive] = useState("crossFire");
  const [sort, setSort] = useState("price");
  const [ascending, setAscending] = useState(true);
  const { search } = useLocation();

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        "https://backend.hoanglecf.vn/api/posts" + search
      );
      setPosts(res.data);
    };
    fetchPosts();
  }, [search]);
  useEffect(() => {
    setShowPopup(true);
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };

  const sortPostsByTitle = (posts) => {
    return posts.sort((a, b) => {
      const titleA = parseInt(a.title.match(/\d+/)[0], 10);
      const titleB = parseInt(b.title.match(/\d+/)[0], 10);
  
      return titleA - titleB;
    });
  };
  
  const sortPostsByPrice = (posts, ascending) => {
    return posts.sort((a, b) => {
      const priceA = parseFloat(a.price.split('/')[1].replace('.', ''));
      const priceB = parseFloat(b.price.split('/')[1].replace('.', ''));
  
      return ascending ? priceB - priceA : priceA - priceB;
    });
  };
  
  const sortPosts = (posts) => {
    let sortedPosts = sortPostsByTitle(posts); 
  
    if (sort === "price") {
      sortedPosts = sortPostsByPrice(sortedPosts, ascending);
    }
  
    return sortedPosts;
  };

  return (
    <div className="home__setBackground">
      {showPopup && <Popup onClose={closePopup} />}
      <Header />
      <div className="home_btn">
        <div>
          <button
            className={active === "crossFire" ? "active" : ""}
            onClick={() => setActive("crossFire")}
          >
            CrossFire
          </button>
          <button
            className={active === "valorant" ? "active" : ""}
            onClick={() => setActive("valorant")}
          >
            Valorant
          </button>
        </div>
        <button onClick={() => {
          setSort("price");
          setAscending(!ascending);
        }}>
          Xếp theo giá
        </button>
      </div>
      <div className="home">
        <Posts posts={sortPosts(posts.filter((post) => post.thumb === active))}/>
      </div>
      <div id="button-contact-vr">
        <div id="tiktok-vr" className="button-contact">
            <div className="phone-vr">
              <div className="phone-vr-circle-fill"></div>
              <div className="phone-vr-img-circle">
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://www.tiktok.com/@thueacccfzomc4"
                >
                  <img src={tiktok} alt="tiktok" />
                </a>
              </div>
            </div>
          </div>
        <div id="fb-vr" className="button-contact">
          <div className="phone-vr">
            <div className="phone-vr-circle-fill"></div>
            <div className="phone-vr-img-circle">
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.facebook.com/groups/1658566368038481/"
              >
                <img src={fbIco} alt="fb_group" />
              </a>
            </div>
          </div>
        </div>
        <div id="zalo-vr" className="button-contact">
          <div className="phone-vr">
            <div className="phone-vr-circle-fill"></div>
            <div className="phone-vr-img-circle">
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://zalo.me/0777650650"
              >
                <img src={zaloIcon} alt="zalo" />
              </a>
            </div>
          </div>
        </div>
        <div id="phone-vr" className="button-contact">
          <div className="phone-vr">
            <div className="phone-vr-circle-fill"></div>
            <div className="phone-vr-img-circle">
              <a href="tel:0777650650" aria-label="Call us at 0777650650">
                <img src={phone} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div id="messenger-vr" className="button-contact">
          <div className="phone-vr">
            <div className="phone-vr-circle-fill"></div>
            <div className="phone-vr-img-circle">
              <a href="https://www.facebook.com/messages/t/100009374322909">
                <img src={messenger} alt="messenger" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
