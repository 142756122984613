import React, { useState } from "react";
import Post from "../post/Post";
import "./posts.css";

export default function Posts({ posts }) {
  const [showAll, setShowAll] = useState(false);

  // const sortedPosts = [...posts].sort((a, b) => parseInt(a.title) - parseInt(b.title));
  const displayedPosts = showAll ? posts : posts.slice(0, 10);
  return (
    <div className="post_session">
      <div className="posts">
        {displayedPosts.map((p) => (
          <Post key={p.id} post={p} />
        ))}
      </div>
        {!showAll && posts.length > 10 && (
          <button onClick={() => setShowAll(true)} className="show-more-button">
            XEM THÊM
          </button>
        )}
    </div>
  );
}
