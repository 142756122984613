import { Link } from "react-router-dom";
import "../posts/posts.css";

export default function Post({ post }) {
  const PF = "https://backend.hoanglecf.vn/images/";

  return (
    <section className="item">
      <Link to={`/post/${post._id}`}>
        {post.photo && <img className="postImg" src={PF + post.photo} alt="" />}

        <div className="item__content">
          <p className="item__title">Giá Thuê: </p>
          <p className="item__desc mb-5">{post.price}₫</p>
          <div className="item__container">
            <div className="item__info">
              <p className="item__title">Mã số : </p>
              <p className="item__desc">{post.title}</p>
            </div>
            <div className="item__info">
              <p className="item__title">Vip</p>
              <p className="item__desc">{post.desc}</p>
            </div>
          </div>
        </div>
      </Link>
      <div className="group__btn">
        <button className="btn">
          <a href={`/post/${post._id}`}>Chi tiết</a>
        </button>

        <button className="btn">
          <a href="https://www.facebook.com/LeHoang1995Z/">Thuê ngay</a>
        </button>
      </div>
    </section>
  );
}
