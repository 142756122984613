import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import "../homepage/homepage.css";
import { phone, tiktok, fbIco, messenger, zaloIcon } from "../../assets";
import Header from "../../components/header/Header";

export default function News() {
  const [post, setPost] = useState([]);
  const { search } = useLocation();
  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        "https://backend.hoanglecf.vn/api/posts" + search
      );
      setPost(res.data?.filter((item) => item.thumb === "guide"));
    };
    fetchPosts();
  }, [search]);
  return (
    <div className="home__setBackground">
      <Header />
      <div className="news__section">
        <h2>
          HOANGLECF.VN <br /> Thuê Acc Đột Kích - Valorant
        </h2>
        <p>
          Cho Thuê ACC CF, Thuê ACC VALORANT xịn thì HOANGLECF.VN là lựa chọn đúng đắn nhất với giá rẻ - uy tín. Dịch vụ của shop là cho THUÊ ACC CF từ C4 cho đến ZomBie tất cả
          các acc đều full item và full đạn, VALORANT gồm những set Skin súng,
          dao mới nhất, đầy đủ với giá rẻ nhất.
        </p>
        <p>
          ❤️ Thuê Acc CF VIP- <strong>3h/45.000₫</strong>: 80 VIP - VIP 6(5201),
          77 VIP - VIP 7(5203), 65 VIP - VIP 8(5204), 78 VIP - VIP 6(5218).
          <br />
          💜 Thuê Acc CF VIP Giá Rẻ - <strong>2h/20.000₫ - 4h/40.000₫</strong>:
          68 VIP - VIP 5(5205), 50 VIP - VIP 4(5206), 47 VIP - VIP 4(5207), 36
          VIP - VIP 5(5208), 37 VIP - VIP 6(5209), 39 VIP - VIP 4(5210), 47 VIP
          - VIP 7(5211), 35 VIP - VIP 5(5212), 41 VIP - VIP 4(5213), 64 VIP -
          VIP 4 (5214), ...
          <br />
          💛 Thuê Acc Đột Kích VIP - <strong>3h/60.000₫</strong>: 82 VIP - VIP
          7(5202), 106 VIP - VIP 7(5215), 101 VIP - VIP 7(5216).
          <br />
          💚 Thuê Acc Valo - UNRANK, BẠC, VÀNG, BẠCH KIM, KIM CƯƠNG, LỤC BẢO,
          IMMORTAL, RADIANT - Liên hệ Admin:{" "}
          <a
            style={{ color: "#396cf7" }}
            href="https://www.facebook.com/LeHoang1995Z?mibextid=ZbWKwL"
            target="_blank"
            rel="noreferrer noopener"
          >
            HOANGLECF.VN{" "}
          </a>
        </p>
        <p>
          💙 HOANGLECF.VN - uy tín ... Còn nhiều mã acc khác nhau nữa đều full
          vip và trang bị cho AE chiến giá cực ưu đãi. AE nhanh tay liên hệ{" "}
          <a
            style={{ color: "#396cf7" }}
            href="https://www.facebook.com/LeHoang1995Z?mibextid=ZbWKwL"
            target="_blank"
            rel="noreferrer noopener"
          >
            HOANGLECF.VN{" "}
          </a>{" "}
          để được hỗ trợ thuê acc Đột Kích, Valorant nhanh nhất.
        </p>
        <p>
          👉 Để thuê acc AE vui lòng liên hệ Admin:{" "}
          <a
            style={{ color: "#396cf7" }}
            href="https://www.facebook.com/LeHoang1995Z?mibextid=ZbWKwL"
            target="_blank"
            rel="noreferrer noopener"
          >
            HOANGLECF.VN{" "}
          </a>
        </p>

        <h2 style={{ textAlign: "center", marginTop: 50 }}>
          Video hướng dẫn thuê acc
        </h2>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: 50,
          }}
        >
          <iframe
            src={post[0]?.desc}
            width="800"
            height="400"
            allow="autoplay"
            title="Hướng dẫn thuê acc"
          />
        </div>
        <p
          style={{
            textTransform: "uppercase",
            fontSize: "1rem",
            color: "white",
          }}
        >
          #THUEACC #GIARE #UYTIN #CF #ACCCF #CFVIP #VALORANT #ACCVALORANT
          #ACCVALO #UNRANK #SILVER(sil) #GOLDEN(GOL) #Platinum(PLA)
          #Diamond(DIA) #Ascendant(ASC) #Immortal(IMM) #Radiant(RAD)
        </p>
      </div>
      <div id="button-contact-vr">
      <div id="tiktok-vr" className="button-contact">
            <div className="phone-vr">
              <div className="phone-vr-circle-fill"></div>
              <div className="phone-vr-img-circle">
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://www.tiktok.com/@thueacccfzomc4"
                >
                  <img src={tiktok} alt="tiktok" />
                </a>
              </div>
            </div>
          </div>
        <div id="fb-vr" className="button-contact">
          <div className="phone-vr">
            <div className="phone-vr-circle-fill"></div>
            <div className="phone-vr-img-circle">
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.facebook.com/groups/1658566368038481/"
              >
                <img src={fbIco} alt="fb_group" />
              </a>
            </div>
          </div>
        </div>
        <div id="zalo-vr" className="button-contact">
          <div className="phone-vr">
            <div className="phone-vr-circle-fill"></div>
            <div className="phone-vr-img-circle">
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://zalo.me/0777650650"
              >
                <img src={zaloIcon} alt="zalo" />
              </a>
            </div>
          </div>
        </div>
        <div id="phone-vr" className="button-contact">
          <div className="phone-vr">
            <div className="phone-vr-circle-fill"></div>
            <div className="phone-vr-img-circle">
              <a href="tel:0777650650">
                <img src={phone} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div id="messenger-vr" className="button-contact">
          <div className="phone-vr">
            <div className="phone-vr-circle-fill"></div>
            <div className="phone-vr-img-circle">
              <a href="https://www.facebook.com/messages/t/100009374322909">
                <img src={messenger} alt="messenger" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
